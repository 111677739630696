/**
 *
 *
 */
export const NAMESPACE = "pattern";

/**
 *
 */
export type Namespace = typeof NAMESPACE;
