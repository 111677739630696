/*                                                   */
/**
 *
 *
 */
import { NAMESPACE, Namespace } from "./consts";
import type { AnyArgs, EmptyEvents, EmptyRecord, EventArgsToFunctionArgs } from "../definitions";
import type { PatternAcceptEventEnum, UpdateAcceptEvents } from "./accept";
import type { GlobalAcceptEvents, StandardAcceptEvents, StandardProvideEvents } from "./events";
import type { AnyPattern, Pattern } from "./pattern";
import type { PatternProvideEventEnum, UpdateProvideEvents } from "./provide";
import type { AnyProps, InputProps } from "../component/props";

export type WrapAccept<
  PP extends AnyPattern,
  Accept extends EmptyRecord,
  Props extends EmptyRecord
> = {
  [P in keyof (UpdateAcceptEvents<PP, Accept & StandardAcceptEvents, Props> &
    GlobalAcceptEvents)]: (
    ...args: EventArgsToFunctionArgs<
      (UpdateAcceptEvents<PP, Accept & StandardAcceptEvents, Props> & GlobalAcceptEvents)[P]
    >
  ) => void;
};

export type WrapProvide<PP extends AnyPattern, Provide extends EmptyEvents> = {
  [P in keyof (Provide & StandardProvideEvents)]: (
    cb: (
      ...args: EventArgsToFunctionArgs<UpdateProvideEvents<PP, Provide & StandardProvideEvents>[P]>
    ) => void
  ) => void;
};

export type WrapConstructor<PP extends AnyPattern, Root, Props> = (
  props: {
    [P in keyof InputProps<Props>]: InputProps<Props>[P];
  },
  root?: Root
) => Promise<PP>;

export type EventAcceptWrapper<PP extends AnyPattern> = PP extends Pattern<
  string,
  infer Root,
  infer Props,
  infer Accept,
  infer Provide,
  any
>
  ? {
      /**
 *
 *
 *
 *
 */
      build: WrapConstructor<PP, Root, Props>;
      on: WrapProvide<PP, Provide>;
      emit: WrapAccept<PP, Accept, Props>;
    }
  : never;

/**
 *
 *
 *
 *
 */
export function eventName<PatternName extends string, Event extends string>(
  pattern: PatternName,
  event: Event
): `${Namespace}.${PatternName}.${Event}` {
  return `${NAMESPACE}.${pattern}.${event}`;
}

export const { eventQBus } = window.o_global;

export function acceptWrapper(patternName: string, event: string): (...incomming: AnyArgs) => void {
  const topic = eventName(patternName, event);
  return (...incomming: AnyArgs): void => {
    eventQBus.emit(topic, ...incomming);
  };
}

export function wrapAccept<
  PP extends AnyPattern,
  Accept extends EmptyEvents,
  Props extends AnyProps
>(patternName: string, accept: PatternAcceptEventEnum<any>): WrapAccept<PP, Accept, Props> {
  return Object.fromEntries(
    Object.entries(accept).map(([key, event]) => [key, acceptWrapper(patternName, event)])
  ) as never;
}

export function provideWrapper(
  patternName: string,
  event: string
): (cb: (...args: AnyArgs) => void) => void {
  const topic = eventName(patternName, event);
  return (cb: (...args: AnyArgs) => void) => {
    eventQBus.on(topic, cb);
  };
}

export function wrapProvide<PP extends AnyPattern, Provide extends EmptyEvents>(
  patternName: string,
  provide: PatternProvideEventEnum<any>
): WrapProvide<PP, Provide> {
  return Object.fromEntries(
    Object.entries(provide).map(([key, event]) => [key, provideWrapper(patternName, event)])
  ) as never;
}

export function wrapConstruct<PP extends AnyPattern, Root, Props>(
  patternName: string
): WrapConstructor<PP, Root, Props> {
  const topic = eventName(patternName, "build");

  return (props, root) => {
    return new Promise<PP>((callback) => {
      eventQBus.once(topic, { element: root, props, callback } as never);
    });
  };
}

export function buildHeadWrapper<P extends AnyPattern>(
  patternName: string,
  accept: PatternAcceptEventEnum<any>,
  provide: PatternProvideEventEnum<any>
): EventAcceptWrapper<P> {
  return {
    build: wrapConstruct<P, any, any>(patternName),
    emit: wrapAccept<P, any, any>(patternName, accept),
    on: wrapProvide<P, any>(patternName, provide),
  } as never;
}
