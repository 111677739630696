import { EmptyRecord } from "../definitions";
import { AnyRef, ref, watch } from "../react";
import { pascalcasify } from "../utils/string";
import { Component } from "./component";
import { constructComponent } from "./construct";
import { DomContext } from "./context";
import { ComponentDefinition } from "./definition";
import { AnyEmits, ComponentEmits } from "./events";
import { AnyProps, InputProps, parseAttributeProp, Properties } from "./props";

export type CustomElement = HTMLElement & {
  get props(): Record<string, AnyRef>;
};

export function registerComponent<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Emits extends AnyEmits,
>(componentDef: ComponentDefinition<Name, Root, Props, Data, Emits>): void {
  type Context = DomContext<Name, Root, Props, Data, Emits>;

  const { name, props: propsDef = {} as Props } = componentDef;
  const observedProps = Object.keys(propsDef);

  /*                      */
  const classDef: CustomElementConstructor = class extends HTMLElement {
    /*              */
    /*         */
    /**/

    connectedCallback(): void {
      const comp = this as unknown as Component<
        Name,
        Root,
        Properties<Props>,
        ComponentEmits<Emits>
      >;

      /*                                                   */
      if (!comp.componentName) {
        const props = Object.fromEntries(
          Object.entries(propsDef).map(([key, defVal]) => {
            const val = parseAttributeProp(defVal, this.getAttribute(key));
            const value = ref(val ?? ("default" in defVal && defVal.default));
            watch(([to]) => this.setAttribute(key, to as string), [value]);
            return [key, { value, sync: true }];
          }),
        ) as InputProps<Props>;

        constructComponent(componentDef, props, this as unknown as Root);
      }

      comp.onMount(this.parentElement as never);
    }

    attributeChangedCallback(key: string, oldValue: unknown, newValue: unknown): void {
      const comp = this as unknown as Component<
        Name,
        Root,
        Properties<Props>,
        ComponentEmits<Emits>
      >;

      const defVal = propsDef[key];
      const propRef = comp.props[key];

      if (newValue !== null) {
        const parser = typeof defVal === "function" ? defVal : defVal.type;
        propRef.value = parser(newValue);
      } else {
        comp.props[key].value = newValue as never;
      }
    }

    static get observedAttributes(): string[] {
      return observedProps;
    }
  };

  /*                      */
  Object.defineProperty(classDef, "name", { value: pascalcasify(name) });
  customElements.define(`pl-${name}`, classDef);
}
